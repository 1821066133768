export function transformToChartData(data, objectKey) {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  const transformedData = {};

  data.forEach((entry) => {
    const { river, [objectKey]: key, avg_temperature } = entry;

    if (!transformedData[key]) {
      transformedData[key] = {};
    }

    transformedData[key][river] = parseFloat(avg_temperature);
  });

  return Object.entries(transformedData).map(([key, rivers]) => ({
    ...rivers,
    [objectKey]: key,
  }));
}
