import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Hidden, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// Define custom styled components for TableCell and TableRow
const StyledTableCell = styled(TableCell)({
  backgroundColor: 'paper', // Lighter background color for table body cells
  color: 'black',
});

const StyledTableHeadCell = styled(TableCell)({
  backgroundColor: '#FFC270', // Darker background color for table head cells
  color: 'black', // Black text color for table head cells
  fontSize: '14', // Larger font size for table head cells
});

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f0f0f0', // Alternate row background color
  },
});

function RecentSensorDataTable() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rawResponse = await fetch("https://river-temp.sipa-e.hr/get-recent-sensor-data.php", {
          headers: {

            "Content-type": "application/json; charset=UTF-8"
          },
        });
        const response = await rawResponse.json();
        setData(response);
      } catch (error) {
        console.log("error fetching data", error);
      }
    };
    fetchData();
  }, []);

  return (
    <TableContainer style={{ height: "100%" }}>
      <Table stickyHeader sx={{ bgcolor: 'background.paper' }}>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell>Lokacija</StyledTableHeadCell>
            <StyledTableHeadCell>Rijeka</StyledTableHeadCell>
            <StyledTableHeadCell>Temperatura</StyledTableHeadCell>
            <StyledTableHeadCell>Vrijeme mjerenja</StyledTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ overflow: "auto" }} >
          {data.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{row.sensorName}</StyledTableCell>
              <StyledTableCell>{row.river}</StyledTableCell>
              <StyledTableCell>{row.temperature}</StyledTableCell>
              <StyledTableCell>{row.datetime}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
}

export default RecentSensorDataTable;
