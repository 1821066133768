import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";

import SearchAppBar from "../components/searchAppBar";
import MapComponent from "../components/mapComponent";
import RiverLineChart from "../components/RiverLineChart";
import { fetchDailyData, fetchHourlyData } from "../services/api";
import { riverColors } from "../const";
import RecentSensorDataTable from "../components/recentSensorDataTable";
import RiverBarChart from "../components/RiverBarChart";

const HomePage = () => {
  const [dailyData, setDailyData] = useState([]);
  const [hourlyData, setHourlyData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const chartDailyData = await fetchDailyData();
        const chartHourlyData = await fetchHourlyData();
        setDailyData(chartDailyData);
        setHourlyData(chartHourlyData);
      } catch (error) {
        console.log("error fetching data", error);
      }
    })();
  }, []);
  console.log("hourly data", hourlyData)
  return (
    <Box bgcolor="background.paper">
      <SearchAppBar />
      <Box boxShadow={4} sx={{ overflow: "auto", bgcolor: "background.paper" }}>
        <MapComponent />
      </Box>
      <Box my={0.5}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} md={3}>
            <Box
              height="250px"
              borderRadius={1.5}
              boxShadow={4}
              p={0.5}
              sx={{
                overflow: "auto",
                bgcolor: "background.paper",
              }}
            >
              <RiverLineChart dataset={hourlyData} riverName="Mreznica" />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              height="250px"
              borderRadius={1.5}
              boxShadow={4}
              p={0.5}
              sx={{ overflow: "auto", bgcolor: "background.paper" }}
            >
              <RiverLineChart dataset={hourlyData} riverName="Korana" />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              height="250px"
              borderRadius={1.5}
              boxShadow={4}
              p={0.5}
              sx={{ overflow: "auto", bgcolor: "background.paper" }}
            >
              <RiverLineChart dataset={hourlyData} riverName="Dobra" />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              height="250px"
              borderRadius={1.5}
              boxShadow={4}
              p={0.5}
              sx={{ overflow: "auto", bgcolor: "background.paper" }}
            >
              <RiverLineChart dataset={hourlyData} riverName="Kupa" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box my={0.5}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} md={4}>
            <Box
              height="300px"
              borderRadius={2}
              boxShadow={4}
              sx={{ overflow: "hidden", bgcolor: "background.paper" }}
            >
              <RecentSensorDataTable />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              height="300px"
              borderRadius={1.5}
              boxShadow={4}
              sx={{ bgcolor: "background.paper" }}
            >
              <Box sx={{ height: "100%" }}>
                <Typography align="center">Tjedni prosjek temperatura</Typography>
                <RiverBarChart
                  dataset={dailyData}
                  rivers={["Mreznica", "Korana", "Dobra", "Kupa"]}
                  colors={riverColors}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HomePage;
