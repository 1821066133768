import React, { useState, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const karlovacCoordinates = {
  lat: 45.492897, // Latitude of Karlovac, Croatia
  lng: 15.555268, // Longitude of Karlovac, Croatia
};

const mapOptions = {
  disableDefaultUI: true,
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }], // Hide points of interest
    },
    {
      featureType: 'transit.station',
      stylers: [{ visibility: 'off' }], // Hide train stations
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }], // Hide highway icons
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ saturation: 100 }], // Fully saturated blue color for water
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ saturation: 20 }], // Reduce saturation for landscape elements
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ saturation: -20 }], // Reduce saturation for roads
    },
  ],
};

const MapComponent = () => {
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyD5gh65GWu079hrWELm1erlAB0iBb2K-Ec',
    libraries,
  });

  const fetchData = async () => {
    try {
      const rawResponse = await fetch('https://river-temp.sipa-e.hr/get-recent-sensor-data.php');
      const response = await rawResponse.json();
      setMarkers(response);
    } catch (error) {
      console.log('error fetching data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={karlovacCoordinates} // Set default center to Karlovac, Croatia
        options={mapOptions} // Apply custom map options
      >
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
            icon={{
              url: marker.temperature < 20
                ? 'https://river-temp.sipa-e.hr/temp-low-marker.png'
                : marker.temperature >= 20 && marker.temperature <= 25
                  ? 'https://river-temp.sipa-e.hr/temp-mid-marker.png'
                  : 'https://river-temp.sipa-e.hr/temp-high-marker.png',
              scaledSize: new window.google.maps.Size(40, 40),
            }}
            onClick={() => setSelectedMarker(marker)}
            label={{
              text: `${marker.temperature}°C`,
              color: 'black',
              fontWeight: 'bold',
              fontSize: '12px',
            }}
          >
            {selectedMarker === marker && (
              <InfoWindow onCloseClick={() => setSelectedMarker(null)}>
                <div>
                  <h2>{marker.sensorName}</h2>
                  <p>Temperature: {marker.temperature}°C</p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    </>
  );
};

export default MapComponent;
