import React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { valueFormatter } from "../const";

const RiverLineChart = ({ dataset, riverName, fillArea = false }) => {
  // Format the timestamp data to display only hours and minutes
  const formattedDataset = dataset.map((dataPoint) => ({
    ...dataPoint,
    hour: new Date(dataPoint.hour).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
  }));

  const seriesOptions = [
    {
      dataKey: riverName,
      label: riverName,
      valueFormatter,
      area: fillArea ? true : undefined,

    },
  ];

  return (
    <LineChart
      dataset={formattedDataset}
      xAxis={[{ scaleType: "band", dataKey: "hour" }]}
      yAxis={[
        {
          scaleType: "linear",
          dataKey: riverName,
          ticks: { stepSize: 5, beginAtZero: true, max: 30 },
        },
      ]}
      series={seriesOptions}
    />
  );
};

export default RiverLineChart;
