import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

import { valueFormatter } from "../const";

const RiverBarChart = ({ dataset, rivers = [], colors = [] }) => {
  return (
    <BarChart
      dataset={dataset}
      xAxis={[{ scaleType: "band", dataKey: "date" }]}
      series={rivers.map((river) => ({
        dataKey: river,
        label: river,
        valueFormatter,
      }))}
      colors={colors}
    />
  );
};

export default RiverBarChart;
