import { transformToChartData } from "./transformations";

export const fetchDailyData = async () => {
  const rawResponse = await fetch(
    "https://river-temp.sipa-e.hr/get-daily-averages-week.php",
    {
      headers: {
        "access-control-allow-origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  const response = await rawResponse.json();
  const chartData = transformToChartData(response, "date");
  return chartData;
};

export const fetchHourlyData = async () => {
  const rawResponse = await fetch(
    "https://river-temp.sipa-e.hr/get-hourly-averages-day.php",
    {
      headers: {
        "access-control-allow-origin": "*",
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  const response = await rawResponse.json();
  const chartData = transformToChartData(response, "hour");
  return chartData;
};
